import { SelectionModel } from '@core/cdk/collections';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { AppConstant } from '@app/app.constant';
import { transformAddressObjectToString } from '@app/core/common/common.utils';
import { Helper } from '@app/core/common/helper';
import { GlobalService } from '@app/core/services/global.service';
import { UuidService } from '@app/core/services/uuid.service';
import { RedrValidator } from '@app/core/validators/redr.validator';
import { PhoneNumber } from '@app/modules/shared/phone-number/phone-number.component';
import { UploadDirective } from '@app/modules/upload/upload.directive';
import { ClientModel } from '@app/shared/models/client.model';
import { MESSAGE_TYPE } from '@app/shared/models/message.model';
import { ClientRoleModel } from '@app/shared/models/metadata.model';
import {
    OneMapResultInterface,
    OneMapResultModel,
} from '@app/shared/models/one-map.model';
import { MetadataApiService } from '@app/shared/services/metadata-api.service';
import { OneMapApiService } from '@app/shared/services/one-map-api.service';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';
import { VendorApiService } from '@app/shared/services/vendor-api.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IQuerySearch } from '@app/shared/models/query-search.model';
import { of } from 'rxjs';
import { VendorModel } from '@app/shared/models/vendor.model';
import { MatDialog } from '@angular/material/dialog';
import { VendorCreateOrUpdateDialogComponent } from '@app/modules/vendor/vendor-create-or-update-dialog/vendor-create-or-update-dialog.component';
import { PolicyService } from '../../permission/policy.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-client-individual-form',
    templateUrl: './client-individual-form.component.html',
    styleUrls: ['./client-individual-form.component.scss'],
})
export class ClientIndividualFormComponent implements OnInit, OnChanges {
    @Output() requestSubmit = new EventEmitter<{
        payload: any;
        form: FormGroup;
    }>();
    @Input() client: ClientModel;
    form: FormGroup;
    clientRoles: ClientRoleModel[] = [];
    oneMapResults: OneMapResultInterface[] = [];
    roleSelected = new SelectionModel<string>(true, []);
    fileUploader;
    allowedMimeType = UploadDirective.imageMineTypes;
    isCompanyLead = false;
    vendorCtrl = new FormControl();
    hasVendorPolicy = {
        view: false,
        create: false,
        update: false,
        access: false,
    };
    maxNoteLength: number = 250;
    currNoteLength: number = 0;

    constructor(
        private metadataApiService: MetadataApiService,
        private formBuilder: FormBuilder,
        private oneMapApiService: OneMapApiService,
        private globalService: GlobalService,
        private translateService: TranslateService,
        private uuidService: UuidService,
        private vendorApiService: VendorApiService,
        private dialog: MatDialog,
        private policyService: PolicyService
    ) {
        this._initForm();
        this.checkPermissionOwner();
    }

    checkPermissionOwner() {
        if (this.policyService.can('vendor::view')) {
            this.hasVendorPolicy.view = true;
        }

        if (this.policyService.can('vendor::update')) {
            this.hasVendorPolicy.update = true;
        }

        if (this.policyService.can('vendor::create')) {
            this.hasVendorPolicy.create = true;
        }

        this.hasVendorPolicy.access =
            this.hasVendorPolicy.view ||
            this.hasVendorPolicy.create ||
            this.hasVendorPolicy.update;
    }

    onVendorChange(event): void {
        if (event.value instanceof VendorModel) {
            this.vendorCtrl.setValue(event.value);
        } else if (event.value === 'createNew') {
            const dialogRef = this.dialog.open(
                VendorCreateOrUpdateDialogComponent
            );
            dialogRef.afterClosed().subscribe((res?: VendorModel) => {
                if (!res) {
                    return;
                }
                this.vendorCtrl.setValue(res);
            });
        }
    }
    removeVendor(): void {
        this.vendorCtrl.reset();
    }
    editVendor(): void {
        const dialogRef = this.dialog.open(
            VendorCreateOrUpdateDialogComponent,
            {
                data: {
                    item: this.vendorCtrl.value,
                },
            }
        );
        dialogRef.afterClosed().subscribe((res?: VendorModel) => {
            if (!res) {
                return;
            }
            this.vendorCtrl.setValue(res);
        });
    }
    ngOnInit(): void {
        this.metadataApiService.getClientRoles().subscribe((data) => {
            this.clientRoles = data;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.client) {
            this._patchValue();
            if (this.client?.mainContact?.fields?.myInfoFields?.length > 0) {
                this._handleDisableMyInfoFields(
                    this.client?.mainContact?.fields?.myInfoFields
                );
            }
        }
    }

    numberOnly(e) {
        return Helper.numberOnly(e);
    }
    onCompanyLeadChange(event: MatCheckboxChange): void {
        this.isCompanyLead = event.checked;
        if (!event.checked) {
            this.vendorCtrl.reset();
        }
    }
    searchWithFn(filters: IQuerySearch) {
        return this.vendorApiService.search(filters);
    }
    private _patchValue() {
        this.fileUploader = {
            key: this.client.logo,
        };
        if (this.client.vendor) {
            this.isCompanyLead = true;
            this.vendorCtrl.setValue(this.client.vendor);
        }
        this.form.patchValue({
            name: this.client.mainContact?.name,
            email: this.client?.mainContact?.email,
            phone: new PhoneNumber({
                phoneNumber: this.client?.mainContact?.phone,
                countryCode: this.client?.mainContact?.countryPhoneCode,
            }),
            nricNumber: this.client?.mainContact?.nricNumber,
            passportNumber: this.client?.mainContact?.passportNumber,
            dateOfBirth: this.client?.mainContact?.dateOfBirth,
            // nationality: !!this.client?.mainContact?.nationality
            //     ? this.client?.mainContact?.nationality
            //     : 'SINGAPORE',

            nationality: !!this.client?.mainContact?.nationality
                ? this.client?.mainContact?.nationality
                : '',
            state: this.client?.mainContact?.fields?.state,
            unitNumber: this.client?.mainContact?.fields?.unitNumber,
            postalCode: this.client?.mainContact?.fields?.postalCode,
            blockNumber: this.client?.mainContact?.fields?.blockNumber,
            streetName: this.client?.mainContact?.fields?.streetName,
            buildingName: this.client?.mainContact?.fields?.buildingName,
            myInfoFields: this.client?.mainContact?.fields?.myInfoFields,
            gender: this.client?.contacts[0]?.fields?.gender,
            salutation: this.client?.contacts[0]?.fields?.salutation,
            residencyStatus: this.client?.contacts[0]?.fields?.residencyStatus,
            citizenship: this.client?.contacts[0]?.fields?.citizenship,
            jobTitle: this.client?.contacts[0]?.fields?.jobTitle,
            companyName: this.client?.contacts[0]?.fields?.companyName,
            landline: this.client?.contacts[0]?.fields?.landline,
            fax: this.client?.contacts[0]?.fields?.fax,
            dictrict: this.client?.contacts[0]?.fields?.dictrict,
            note: this.client?.note ?? '',
        });

        this.roleSelected.select(...this.client.roles);
    }

    private _initForm() {
        this.form = this.formBuilder.group({
            name: [
                '',
                [
                    Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.NAME_LENGTH
                    ),
                ],
            ],
            email: [
                '',
                [
                    RedrValidator.email,
                    // Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.EMAIL_LENGTH
                    ),
                ],
            ],
            phone: [
                '',
                [
                    // Validators.required,
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.PHONE_LENGTH
                    ),
                ],
            ],
            nricNumber: [
                '',
                [
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.NRIC_NUMBER_LENGTH
                    ),
                ],
            ],
            passportNumber: [
                '',
                [
                    Validators.maxLength(
                        AppConstant.FIELD_VALIDATION.PASSPORT_LENGTH
                    ),
                ],
            ],
            dateOfBirth: ['', []],
            nationality: ['', []],
            postalCode: ['', []],
            blockNumber: ['', []],
            streetName: ['', []],
            unitNumber: ['', []],
            buildingName: ['', []],
            myInfoFields: ['', []],
            gender: [],
            salutation: [],
            residencyStatus: [],
            citizenship: ['', []],
            jobTitle: ['', []],
            companyName: ['', []],
            landline: ['', []],
            fax: ['', []],
            dictrict: ['', []],
            note: ['', [this.validatorNoteCKEditor]],
        });

        this.form.controls.postalCode.valueChanges
            .pipe(debounceTime(AppConstant.DEFAULT_SETTINGS.DEBOUNCE_TIME))
            .subscribe((value) => {
                if (value instanceof OneMapResultModel) {
                    this._selectPostalCode(value);
                } else {
                    this._searchPostalCode(value);
                }
            });

        this.form.controls.note.valueChanges
            .subscribe((value) => {
                const textConvertFromValue = Helper.getContentCKEditor(value);
                this.currNoteLength = textConvertFromValue.length;
            });
    }

    validatorNoteCKEditor = (control: FormControl): ValidationErrors | null => {
        const value = control.value;
        const textConvertFromValue = Helper.getContentCKEditor(value, {trimValue: true});
        const textLength = textConvertFromValue.length;

        if (textLength > this.maxNoteLength) return {
            max: {
                max: this.maxNoteLength,
                actual: textLength,
            }
        }

        if (!this.isCompanyLead) return null;

        if (!textLength) return {
            required: true,
        };

        return null;
    }

    private _selectPostalCode(data: OneMapResultModel) {
        const temp = this._removeDataNullFromOneMapAddress(data);
        this.form.patchValue({
            blockNumber: temp.BLK_NO,
            streetName: temp.ROAD_NAME,
            buildingName: temp.BUILDING,
            postalCode: temp.POSTAL,
            unitNumber: null,
        });
    }

    private _removeDataNullFromOneMapAddress(
        data: OneMapResultModel
    ): OneMapResultModel {
        Object.keys(data).forEach((key) => {
            if (data[key] === 'NIL') {
                data[key] = '';
            }
        });

        return data;
    }

    private _searchPostalCode(value: string) {
        if (value) {
            this.oneMapApiService
                .search({
                    searchVal: value,
                    returnGeom: 'N',
                    getAddrDetails: 'Y',
                    // pageNum: '1'
                })
                .subscribe(
                    (data: {
                        found: number;
                        pageNum: number;
                        totalNumPages: number;
                        results: OneMapResultModel[];
                    }) => {
                        this.oneMapResults = data.results
                            .filter((v) => v.POSTAL !== 'NIL')
                            .map((v) => OneMapResultModel.fromJson(v));
                    }
                );
        }
    }
    private _handleDisableMyInfoFields(myInfoFields: string[]) {
        myInfoFields.forEach((field) => {
            if (field.substring(0, 6) === 'fields') {
                field = field.slice(7);
            }
            this.form.get(field).disable();
        });
    }

    onFinishedFile(file) {
        if (file.completed) {
            this.fileUploader = file;
        } else {
            this.globalService.message.next({
                type: MESSAGE_TYPE.error,
                message: this.translateService.instant(file.error.message),
            });
        }
    }

    submit() {
        if (this.form.invalid) {
            return;
        }

        const rawData = this.form.getRawValue();
        const profile = rawData.phone as PhoneNumber;
        const data = {
            type: AppConstant.CUSTOMER_TYPE.INDIVIDUAL,
            name: rawData.name,
            roles: this.roleSelected.selected,
            note: '',
            addressLine1: transformAddressObjectToString({
                unitNumber: rawData.unitNumber,
                postalCode: rawData.postalCode,
                blockNumber: rawData.blockNumber,
                streetName: rawData.streetName,
                buildingName: rawData.buildingName,
                country: rawData.nationality,
            }),
            contacts: [
                {
                    name: rawData.name,
                    email: rawData.email,
                    phone: profile?.phoneNumber ?? null,
                    countryPhoneCode: profile?.countryCode ?? this.client?.mainContact?.countryPhoneCode ?? null,
                    nricNumber: rawData.nricNumber,
                    passportNumber: rawData.passportNumber,
                    dateOfBirth: rawData.dateOfBirth,
                    nationality: rawData.nationality,
                    fields: {
                        unitNumber: rawData.unitNumber,
                        postalCode: rawData.postalCode,
                        blockNumber: rawData.blockNumber,
                        streetName: rawData.streetName,
                        buildingName: rawData.buildingName,
                        nationality: rawData.nationality,
                        myInfoFields: rawData.myInfoFields,
                        gender: rawData.gender,
                        salutation: rawData.salutation,
                        residencyStatus: rawData.residencyStatus,
                        citizenship: rawData.citizenship,
                        companyName: rawData.companyName,
                        jobTitle: rawData.jobTitle,
                        landline: rawData.landline,
                        fax: rawData.fax,
                        dictrict: rawData.dictrict,
                    },
                },
            ],
        };

        if (Helper.getContentCKEditor(rawData.note, {trimValue: true}).length) {
            if (rawData.note.match(/^<div[^>]*>[\s\S]*<\/div>$/)) {
                data.note = rawData.note;
            } else {
                data.note = `<div class="ck ck-content ck-print">${rawData.note}</div>`
            }
        }

        if (this.fileUploader) {
            data['logo'] = this.fileUploader.key;
            data.contacts[0]['avatar'] = this.fileUploader.key;
        }
        if (this.vendorCtrl.value) {
            data['vendorId'] = this.vendorCtrl.value.id;
        }

        this.requestSubmit.emit({
            payload: data,
            form: this.form,
        });
    }

    displayWith(value: OneMapResultModel): string {
        if (value instanceof OneMapResultModel) {
            return value.POSTAL;
        } else {
            return value;
        }
    }

    customizeFileNameFn(file: File): string {
        return `${this.uuidService.generate()}`;
    }
}
